import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const loginUser = createAsyncThunk(
  'users/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORTAL_API + '/api/v1/auth',
        {
          email,
          password,
        }
      );
      let data = await response.data;

      if (response.status === 200) {
        let decoded = jwt_decode(data.accessToken, { complete: true });

        try {
          sessionStorage.setItem('token', data.accessToken);
          sessionStorage.setItem(
            'user',
            JSON.stringify({
              uid: decoded.uid,
              name: decoded.name,
              email: decoded.email,
              family_name: decoded.family_name,
              oid: decoded.oid,
              organization: decoded.organization,
              company: decoded.company,
              role: decoded.role,
            })
          );
        } catch (e) {
          console.log(e);
        }
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// New Microsoft SSO Login
export const microsoftSSOLogin = createAsyncThunk(
  'users/ssoLogin',
  async ({ idToken }, thunkAPI) => {
    try {
      debugger
      const response = await axios.post(
        process.env.REACT_APP_PORTAL_API + '/api/v1/auth/sso-login',
        {
          idToken,
        }
      );
      let data = await response.data;

      if (response.status === 200) {
        let decoded = jwt_decode(data.accessToken, { complete: true });

        try {
          sessionStorage.setItem('token', data.accessToken);
          sessionStorage.setItem(
            'user',
            JSON.stringify({
              uid: decoded.uid,
              name: decoded.name,
              email: decoded.email,
              family_name: decoded.family_name,
              oid: decoded.oid,
              organization: decoded.organization,
              company: decoded.company,
              role: decoded.role,
            })
          );
        } catch (e) {
          console.log(e);
        }
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response?.data || { message: 'SSO login failed' });
    }
  }
);

// Redux slice for auth
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {
      name: null,
      family_name: null,
      organization: null,
      company: null,
    },
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
    },
  },
  extraReducers: {
    // Email/Password login
    [loginUser.fulfilled]: (state, { payload }) => {
      let decoded = jwt_decode(payload.accessToken, { complete: true });
      state.user = {
        name: decoded.name,
        family_name: decoded.family_name,
        organization: decoded.organization,
        company: decoded.company,
      };
      state.isFetching = false;
      state.isSuccess = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },

    // Microsoft SSO login
    [microsoftSSOLogin.fulfilled]: (state, { payload }) => {
      let decoded = jwt_decode(payload.accessToken, { complete: true });
      state.user = {
        name: decoded.name,
        family_name: decoded.family_name,
        organization: decoded.organization,
        company: decoded.company,
      };
      state.isFetching = false;
      state.isSuccess = true;
    },
    [microsoftSSOLogin.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message || 'SSO login failed';
    },
    [microsoftSSOLogin.pending]: (state) => {
      state.isFetching = true;
    },
  },
});

export const { clearState } = authSlice.actions;

export const authSelector = (state) => state.auth;
