import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import appLogo from '../assets/images/Logo-01.svg';
import ThemeContext from '../contexts/ThemeContext';
import { authSelector, clearState, loginUser, microsoftSSOLogin } from '../redux/AuthSlice';
import { loginRequest } from '../msal-config';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loginAppLogo, loginPageType } = useContext(ThemeContext);
    const { isSuccess, isError, errorMessage } = useSelector(authSelector);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [message, setMessage] = useState();

    const { instance } = useMsal();
    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(loginUser({ email: email, password }));
    };

    const handleSignOut  = async () => {
        try {
            await instance.logoutRedirect({postLogoutRedirectUri: window.location.origin});
        } catch (error) {
            console.error("Error during redirect sign-out:", error);
        }
    };

    const handleMicrosoftLogin = async () => {
        try {
            const response = await instance.loginPopup(loginRequest);
            const idToken = response.idToken;

            dispatch(microsoftSSOLogin({ idToken }))
                .unwrap()
                .then(() => {
                    console.log('SSO login successful');
                })
                .catch((err) => {
                    console.error('SSO login failed:', err);
                });
        } catch (error) {
            console.error('Microsoft Login Error:', error);
        }
    };
    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        if (isError) {
            setMessage(errorMessage);
            dispatch(clearState());
        }

        if (isSuccess) {
            dispatch(clearState());
            navigate('/');
        }
    }, [isError, isSuccess]);

    return (
        <>
            {loginPageType === 'basic' ? (
                <div className="auth auth-basic">
                    <form
                        className="auth-form"
                        style={{ backgroundColor: 'transparent' }}
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                    >
                        <div className="mb-4">
                            <div className="mb-3">
                                {loginAppLogo && loginAppLogo.length > 0 ? (
                                    <img src={loginAppLogo} alt="" height="72"></img>
                                ) : (
                                    <img src={appLogo} alt="" height="72"></img>
                                )}
                            </div>
                            <h1 className="h3">Sign In</h1>
                            <p className="mb-4">Enter your email and password to sign in</p>
                        </div>
                        <div className="form-group mb-4">
                            <input
                                type="text"
                                id="inputEmail"
                                className="form-control form-control-lg"
                                placeholder="Email"
                                autoFocus=""
                                autoComplete="new-email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <input
                                type="password"
                                id="inputPassword"
                                className="form-control form-control-lg"
                                placeholder="Password"
                                autoComplete="new-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {message && (
                            <div className="form-group">
                                <p style={{ color: 'red' }}> {message} </p>
                            </div>
                        )}
                        <div className="form-group mx-auto p-1 d-flex justify-content-between">
                            <div className="custom-control custom-control-inline custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="remember-me" />
                                <label className="custom-control-label" htmlFor="remember-me">
                                    Remember me
                                </label>
                            </div>
                            <div>
                                <Link className="link" style={{ textDecoration: 'none' }} to="/forgot-password">
                                    Forgot Password
                                </Link>
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <button className="btn btn-lg btn-primary btn-block" type="submit">
                                Sign In
                            </button>
                        </div>
                        <div className="form-group mb-4">
                            <button
                                type="button"
                                className="btn btn-lg btn-secondary btn-block"
                                onClick={handleMicrosoftLogin}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                ><object style={{ marginRight: '8px' }} type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"></object>
                                Sign In with Microsoft
                            </button>
                        </div>
                        {instance.getAllAccounts().length > 0 && (
                            <div>
                                <span 
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSignOut();
                                    }}
                                >
                                    Sign out and login with a different account
                                </span>
                            </div>
                        )}
                    </form>
                </div>
            ) : (
                <div className="auth auth-floated">
                    <form className="auth-form" onSubmit={handleSubmit} noValidate autoComplete="off">
                        <div className="mb-4">
                            <div className="mb-3">
                                {loginAppLogo && loginAppLogo.length > 0 ? (
                                    <img src={loginAppLogo} alt="" height="72"></img>
                                ) : (
                                    <img src={appLogo} alt="" height="72"></img>
                                )}
                            </div>
                            <h1 className="h3">Sign In</h1>
                            <p className="mb-4">Enter your email and password to sign in</p>
                        </div>
                        <div className="form-group mb-4">
                            <input
                                type="text"
                                id="inputEmail"
                                className="form-control form-control-lg"
                                placeholder="Email"
                                autoFocus=""
                                autoComplete="new-email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <input
                                type="password"
                                id="inputPassword"
                                className="form-control form-control-lg"
                                placeholder="Password"
                                autoComplete="new-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {message && (
                            <div className="form-group">
                                <p style={{ color: 'red' }}> {message} </p>
                            </div>
                        )}
                        <div className="form-group mx-auto p-1 d-flex justify-content-between">
                            <div className="custom-control custom-control-inline custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="remember-me" />
                                <label className="custom-control-label" htmlFor="remember-me">
                                    Remember me
                                </label>
                            </div>
                            <div>
                                <Link className="link" style={{ textDecoration: 'none' }} to="/forgot-password">
                                    Forgot Password
                                </Link>
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <button className="btn btn-lg btn-primary btn-block" type="submit">
                                Sign In
                            </button>
                        </div>
                        <div className="form-group mb-4">
                            <button
                                type="button"
                                className="btn btn-lg btn-secondary btn-block"
                                onClick={handleMicrosoftLogin}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                ><object style={{ marginRight: '8px' }} type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"></object>
                                Sign In with Microsoft
                            </button>
                        </div>
                        {instance.getAllAccounts().length > 0 && (
                            <div>
                                <span 
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSignOut();
                                    }}
                                >
                                    Sign out and login with a different account
                                </span>
                            </div>
                        )}
                    </form>
                    <div id="auth-announcement" className="auth-announcement"></div>
                </div>
            )}
        </>
    );
};

export default Login;
