import { PublicClientApplication } from "@azure/msal-browser";

const baseUrl = window.location.origin;

export const msalConfig = {
  auth: {
    clientId: "245f2d8e-0944-4634-b428-b9b331219681",
    authority: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    redirectUri: baseUrl,
    postLogoutRedirectUri: baseUrl,
  },
  cache: {
    cacheLocation: "localStorage", 
    storeAuthStateInCookie: false, 
  },
};

export const loginRequest = {
  scopes: ["openid", "offline_access"]
};

export const msalInstance = new PublicClientApplication(msalConfig);